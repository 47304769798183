import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <div style={{ marginTop: '100px' }} />
      <section className="hero">
        <div className="hero-body">
          <div className="container mx-6">
            <h1 className="title">
              NOT FOUND
            </h1>
            <h2 className="subtitle">
              You just hit a route that doesn&#39;t exist... the sadness.
            </h2>
          </div>
        </div>
      </section>
      <div style={{ marginTop: '100px' }} />
    </div>
  </Layout>
)

export default NotFoundPage
